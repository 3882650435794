.CheckOutlined{
    color: green;
}

.dataBillModalIframe{
    height: 70vh;
    align-content: center;
   

}

.modalFactura{
    margin: auto;
}