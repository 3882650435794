
   .form-login.ant-form{
    margin: 0px auto;
    overflow: hidden;
    width: max-content;
    position: absolute;
    top: 20%;
    left: 50%;
    transform:translateX(-50%);
  }

  .Boton_login{
    .ant-btn-round.ant-btn-lg {
        margin: 0 auto;
        display: block;
    }
  }