body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('https://www.xtrafondos.com/wallpapers/montanas-en-bosque-minimalista-flat-3306.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout-sider-children {
 background-color: #188fff;
}

ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  opacity: 0.9;
}

section.ant-layout.site-layout {
  background: white;
}
.ant-layout-header {
  background:#188fff !important;
}

header.ant-layout-header.site-layout-background {
  height: 7.5vh;
}

.ant-layout-sider-trigger{
    background-color:#188fff  !important;
    position: fixed !important;
    font-size: 25px !important;
    line-height: 10px !important;
}

.ant-card.ant-card-bordered {
  margin-bottom: 30px;
  /* box-shadow: rgb(0 0 0 / 85%) 0px 0px 20rem 20px; */
  border: 3px solid #fafafa;
  border-radius: 0.375rem;
  -webkit-box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 5px 5px 0px rgb(0 0 0 / 75%);
  background: #188fff;
}

.ant-modal-content{
  -webkit-box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%) !important;
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 3px 5px 5px 0px rgb(0 0 0 / 75%) !important;
}

.ant-card-meta-title {
  color: white !important;
}





@media screen and (max-width: 800px) {
  .ant-layout-sider {
    display: none !important;
  }
}
