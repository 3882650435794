.LogoH1{
    display: flex;
    color:white;
    font-weight: bold;
    position: relative;
    justify-content: space-around;
    margin: 25.5px;
    /* font-size: 22px; */
}

@media screen and  (min-width:820px) {
    .buttonCollapse{
      display: none;
    } 
}